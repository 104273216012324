<template>
    <div class="calc d-flex flex-column mt-7 mt-md-5">
        <img src="https://derivative-calculator.org/robot_top_s.webp" width="135" height="122" class="robot-top" />
        <div class="input flex-column justify-content-between align-items-start rounded-top-4 p-3" :class="{empty: this.expression.length == 0}">
            <div class="preview position-absolute" id="math-preview" v-if="this.$mathml" v-html="this.TexDisplay"></div>
            <div class="preview small" id="math-preview" v-else>{{ this.TexDisplay }}</div>
            <div class="d-flex justify-content-center align-items-end cnt rounded-top-4 rounded-bottom-1" @click="this.$refs.textarea.focus()">
                <textarea name="text" id="text" spellcheck="false" autocomplete="off" class="font-sizer-18 fw-bold w-100 text-center text p-3 border-0 rounded-top-4 rounded-bottom-1 d-none d-md-block" row="1" data-autoresize :placeholder="this.$t('calc.placeholder_derive')" v-model="this.expression" ref="textarea" @keydown.enter.prevent="this.Compute()"></textarea>
                <div class="d-flex flex-wrap d-md-none text-center font-sizer-18 fw-bold font-monospace mobile-text p-3">
                    <span v-for="(chr, index) in this.getChars" @click.prevent="setCursor(index)" :class="{cursor: this.cursorPos == index}">{{ chr }}</span>
                    <span :class="{cursor: this.cursorPos == this.expression.length}" @click.prevent="setCursor(this.expression.length)">&nbsp;</span>
                </div>
            </div>
        </div>
        <Collapse :show="this.ErrorShow && this.ErrorMsg && this.hasSyntaxError">
            <div class="error bg-primary small text-white">
                <div class="p-2 ps-3">
                    <strong>{{ this.$t("calc.error") }}: </strong>{{ this.ErrorMsg }}
                </div>
            </div>
        </Collapse>
        <div class="options p-2 rounded-bottom-3 small">
            <div class="row d-flex justify-content-end">
                <div class="col col-md-1 align-items-center d-flex ps-3">
                    <i class="fal fa-gear font-size-12"></i>
                </div>
                <div class="col-8 text-end pe-3 d-flex d-md-none" @click="this.showOptions = !this.showOptions">
                    <div class="fw-bold text-end me-4">{{ this.$t("calc.show-options") }}</div>
                </div>

                <div class="col-8 col-md-10 row d-none d-md-flex">
                    <div class="text-end flex-row align-items-center justify-content-between justify-content-md-around pe-3 d-none d-md-flex">
                        <div class="form-check mb-0 form-switch form-switch-sm">
                            <input class="form-check-input" type="checkbox" id="option-steps" v-model="this.showSteps" />
                            <label class="form-check-label" for="option-steps">
                                {{ this.$t("calc.desktop.show-steps-switch") }}
                            </label>
                        </div>
                        <div class="form-check mb-0 form-switch form-switch-sm">
                            <input class="form-check-input" type="checkbox" value="" id="option-simplify" v-model="this.simplifyExpression" />
                            <label class="form-check-label" for="option-simplify">
                                {{ this.$t("calc.desktop.simp-switch") }}
                            </label>
                        </div>
                        <div class="d-flex flex-row align-items-center">
                            <div>{{ this.$t("calc.wrt") }}</div>
                            <select class="form-select form-select-sm border-2 ms-2" aria-label="with respect to:" id="wrt" v-model="this.variable">
                                <option v-for="v in this.diffVariables">{{ v }}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="col-2 col-md-1 align-items-center d-flex ps-3" @click="this.ErrorShow = !this.ErrorShow">
                    <i class="fal fa-triangle-exclamation font-size-12 text-dyellow" v-if="this.hasSyntaxError"></i>
                </div>
            </div>
            <Collapse :show="this.showOptions">
                <div class="d-flex flex-column align-items-start m-3">
                    <div class="form-check mb-0 form-switch form-switch-md cen">
                        <input class="form-check-input" type="checkbox" id="option-steps-m" v-model="this.showSteps" />
                        <label class="form-check-label ms-3 font-size-10 fw-bold" for="option-steps-m">
                            {{ this.$t("calc.desktop.show-steps-switch") }}
                        </label>
                    </div>
                    <div class="form-check mb-0 mt-3 form-switch form-switch-md cen">
                        <input class="form-check-input" type="checkbox" value="" id="option-simplify-m" v-model="this.simplifyExpression" />
                        <label class="form-check-label ms-3 font-size-10 fw-bold" for="option-simplify-m">
                            {{ this.$t("calc.desktop.simp-switch") }}
                        </label>
                    </div>
                    <div class="d-flex flex-row align-items-center mt-3">
                        <div class="font-size-10 fw-bold">with respect to: </div>
                        <select class="form-select form-select-sm border-2 ms-2 font-serif fst-italic font-size-11" aria-label="with respect to:" v-model="this.variable">
                            <option v-for="v in this.diffVariables">{{ v }}</option>
                        </select>
                    </div>
                </div>
            </Collapse>
        </div>
        <div class="buttons-mobile pt-2 d-grid d-md-none">
            <div class="row bctn gx-2">
                <div class="col-3"><button class="btn btn-dark2 cen" @click.prevent="this.setCursor(0)"><i class="far fa-arrow-left-to-bracket"></i></button></div>
                <div class="col-3"><button class="btn btn-dark2 cen" @click.prevent="this.setCursor(this.cursorPos - 1)"><i class="far fa-arrow-left-long"></i></button></div>
                <div class="col-3"><button class="btn btn-dark2 cen" @click.prevent="this.setCursor(this.cursorPos + 1)"><i class="far fa-arrow-right-long"></i></button></div>
                <div class="col-3"><button class="btn btn-dark2 cen" @click.prevent="this.setCursor(this.expression.length)"><i class="far fa-arrow-right-to-bracket"></i></button></div> 
            </div>
            <div class="row bctn low gx-2 mt-3">
                <div class="col-3">
                    <button :class="{'btn-dyellow': this.currentSlide == 0, 'btn-outline-light': this.currentSlide != 0, 'text-body-tertiary': this.currentSlide != 0}" class="rounded-pill btn p-0 d-flex justify-content-center align-items-center" @click.prevent="this.currentSlide = 0">
                        <i class="far fa-hashtag"></i>
                    </button>
                </div>
                <div class="col-3">
                    <button :class="{'btn-dyellow': this.currentSlide == 1, 'btn-outline-light': this.currentSlide != 1, 'text-body-tertiary': this.currentSlide != 1}" class="rounded-pill btn p-0 d-flex justify-content-center align-items-center" @click.prevent="this.currentSlide = 1">
                        <i class="far fa-square-root"></i>
                    </button>
                </div>
                <div class="col-3">
                    <button :class="{'btn-dyellow': this.currentSlide == 2, 'btn-outline-light': this.currentSlide != 2, 'text-body-tertiary': this.currentSlide != 2}" class="rounded-pill btn p-0 d-flex justify-content-center align-items-center" @click.prevent="this.currentSlide = 2">
                        <i class="far fa-wave-sine"></i>
                    </button>
                </div> 
                <div class="col-3">
                    <button :class="{'btn-dyellow': this.currentSlide == 3, 'btn-outline-light': this.currentSlide != 3, 'text-body-tertiary': this.currentSlide != 3}" class="rounded-pill btn p-0 d-flex justify-content-center align-items-center" @click.prevent="this.currentSlide = 3">
                        <i class="far fa-a font-size-9"></i>
                    </button>
                </div>
            </div>

            <Carousel v-model="currentSlide">
                <Slide key="num">
                    <div class="grid5 mt-3 w-100">
                        <div class="b1"><button class="btn btn-info" @click.prevent="this.insert('()/()')"><sup><var>u</var></sup>/<sub><var>v</var></sub></button></div>
                        <div class="b2"><button class="btn btn-info" @click.prevent="this.insert('/')">&div;</button></div>
                        <div class="b3"><button class="btn btn-info" @click.prevent="this.insert('*')">&times;</button></div>
                        <div class="b4"><button class="btn btn-info delete" id="delete" @click.prevent="this.delete()"><i class="fa-solid fa-delete-left"></i></button></div>
                        <div class="b5"><button class="btn btn-secondary" @click.prevent="this.insert('7')">7</button></div>
                        <div class="b6"><button class="btn btn-secondary" @click.prevent="this.insert('8')">8</button></div>
                        <div class="b7"><button class="btn btn-secondary" @click.prevent="this.insert('9')">9</button></div>
                        <div class="b8"><button class="btn btn-info" @click.prevent="this.insert('-')">&minus;</button></div>
                        <div class="b9"><button class="btn btn-secondary" @click.prevent="this.insert('4')">4</button></div>
                        <div class="b10"><button class="btn btn-secondary" @click.prevent="this.insert('5')">5</button></div>
                        <div class="b11"><button class="btn btn-secondary" @click.prevent="this.insert('6')">6</button></div>
                        <div class="b12"><button class="btn btn-info" @click.prevent="this.insert('+')">+</button></div>
                        <div class="b13"><button class="btn btn-secondary" @click.prevent="this.insert('1')">1</button></div>
                        <div class="b14"><button class="btn btn-secondary" @click.prevent="this.insert('2')">2</button></div>
                        <div class="b15"><button class="btn btn-secondary" @click.prevent="this.insert('3')">3</button></div>
                        <div class="b16"><button class="btn btn-secondary" @click.prevent="this.insert('0')">0</button></div>
                        <div class="b17"><button class="btn btn-secondary" @click.prevent="this.insert('.')">.</button></div>
                        <div class="b18"><button class="btn btn-secondary" @click.prevent="this.insert('-')">(-)</button></div>
                        <div class="b19"><button class="btn btn-primary fw-bold text-white" id="go" @click.prevent="this.Compute()" :disabled="this.isWorking">
                            {{ this.$t("calc.go") }}
                        </button></div>
                    </div>
                </Slide>
                
                <Slide key="func">
                    <div class="grid3 w-100 mt-3">
                        <div class="b1"><button class="btn btn-secondary" @click.prevent="this.insert('(')">(</button></div>
                        <div class="b2"><button class="btn btn-secondary" @click.prevent="this.insert('x')"><var>x</var></button></div>
                        <div class="b3"><button class="btn btn-secondary" @click.prevent="this.insert(')')">)</button></div>
                        <div class="b4"><button class="btn btn-secondary" @click.prevent="this.insert('^2')"><var>x</var><sup class="m1">2</sup></button></div>
                        <div class="b5"><button class="btn btn-secondary" @click.prevent="this.insert('^3')"><var>x</var><sup class="m1">3</sup></button></div>
                        <div class="b6"><button class="btn btn-secondary" @click.prevent="this.insert('^()')"><var>x</var><sup class="m1"><var>n</var></sup></button></div>
                        <div class="b7"><button class="btn btn-secondary" @click.prevent="this.insert('sqrt()')"><sup>2</sup>&radic;</button></div>
                        <div class="b8"><button class="btn btn-secondary" @click.prevent="this.insert('^(1/3)')"><sup>3</sup>&radic;</button></div>
                        <div class="b9"><button class="btn btn-secondary" @click.prevent="this.insert('^(1/)')"><sup><var>n</var></sup>&radic;</button></div>
                        <div class="b10"><button class="btn btn-secondary" @click.prevent="this.insert('ln()')">ln</button></div>
                        <div class="b11"><button class="btn btn-secondary" @click.prevent="this.insert('log()')">log<sub>10</sub></button></div>
                        <div class="b12"><button class="btn btn-secondary" @click.prevent="this.insert('i')"><var>i</var></button></div>
                        <div class="b13"><button class="btn btn-secondary" @click.prevent="this.insert('e')"><var>e</var></button></div>
                        <div class="b14"><button class="btn btn-secondary" @click.prevent="this.insert('e^()')"><var>e</var><sup class="m1"><var>x</var></sup></button></div>
                        <div class="b15"><button class="btn btn-secondary" @click.prevent="this.insert('pi')">&pi;</button></div>
                    </div>
                </Slide>

                <Slide key="trig">
                    <div class="grid3 w-100 mt-3">
                        <div class="b1"><button class="btn btn-secondary" @click.prevent="this.insert('sin()')">sin</button></div>
                        <div class="b2"><button class="btn btn-secondary" @click.prevent="this.insert('cos()')">cos</button></div>
                        <div class="b3"><button class="btn btn-secondary" @click.prevent="this.insert('tan()')">tan</button></div>
                        <div class="b4"><button class="btn btn-secondary" @click.prevent="this.insert('asin()')">sin<sup>-1</sup></button></div>
                        <div class="b5"><button class="btn btn-secondary" @click.prevent="this.insert('acos()')">cos<sup>-1</sup></button></div>
                        <div class="b6"><button class="btn btn-secondary" @click.prevent="this.insert('atan()')">tan<sup>-1</sup></button></div>
                        <div class="b7"><button class="btn btn-secondary" @click.prevent="this.insert('csc()')">csc</button></div>
                        <div class="b8"><button class="btn btn-secondary" @click.prevent="this.insert('sec()')">sec</button></div>
                        <div class="b9"><button class="btn btn-secondary" @click.prevent="this.insert('cot()')">cot</button></div>
                        <div class="b10"><button class="btn btn-secondary" @click.prevent="this.insert('sinh()')">sinh</button></div>
                        <div class="b11"><button class="btn btn-secondary" @click.prevent="this.insert('cosh()')">cosh</button></div>
                        <div class="b12"><button class="btn btn-secondary" @click.prevent="this.insert('tanh()')">tanh</button></div>
                        <div class="b13"><button class="btn btn-secondary" @click.prevent="this.insert('asinh()')">sinh<sup>-1</sup></button></div>
                        <div class="b14"><button class="btn btn-secondary" @click.prevent="this.insert('acosh()')">cosh<sup>-1</sup></button></div>
                        <div class="b15"><button class="btn btn-secondary" @click.prevent="this.insert('atanh()')">tanh<sup>-1</sup></button></div>
                    </div>
                </Slide>

                <Slide key="alpha">
                    <div class="grid-alpha mt-3 w-100">
                        <div v-for="(alpha, index) in this.validAlpha" :key="index" :class="'b' + (index+1)">
                            <button class="btn btn-secondary" @click.prevent="this.insert(alpha)">
                                <var class="font-size-13">{{ alpha }}</var>
                            </button>
                        </div>
                    </div>
                </Slide>
            </Carousel>
        </div>
        <div class="buttons pt-2 d-none d-md-grid">
            <div class="b1"><button class="btn btn-secondary" @click.prevent="this.insert('sin()')">sin</button></div>
            <div class="b2"><button class="btn btn-secondary" @click.prevent="this.insert('cos()')">cos</button></div>
            <div class="b3"><button class="btn btn-secondary" @click.prevent="this.insert('tan()')">tan</button></div>
            <div class="b4"><button class="btn btn-light" @click.prevent="this.insert('(')">(</button></div>
            <div class="b5"><button class="btn btn-light" @click.prevent="this.insert(')')">)</button></div>
            <div class="b6"><button class="btn btn-info" @click.prevent="this.insert('()/()')"><sup><var>u</var></sup>/<sub><var>v</var></sub></button></div>
            <div class="b7"><button class="btn btn-info" @click.prevent="this.insert('/')">&div;</button></div>
            <div class="b8"><button class="btn btn-info" @click.prevent="this.insert('*')">&times;</button></div>
            <div class="b9"><button class="btn btn-info delete" @click.prevent="this.deleteLastCharacterInTextarea()"><i class="fa-solid fa-delete-left"></i></button></div>
            <div class="b10"><button class="btn btn-secondary" @click.prevent="this.insert('asin()')">sin<sup>-1</sup></button></div>
            <div class="b11"><button class="btn btn-secondary" @click.prevent="this.insert('acos()')">cos<sup>-1</sup></button></div>
            <div class="b12"><button class="btn btn-secondary" @click.prevent="this.insert('atan()')">tan<sup>-1</sup></button></div>
            <div class="b13"><button class="btn btn-light" @click.prevent="this.insert('^()')"><var style="margin-right:1px">x</var><sup><var>n</var></sup></button></div>
            <div class="b14"><button class="btn btn-light" @click.prevent="this.insert('e^()')"><var style="margin-right:1px">e</var><sup><var>x</var></sup></button></div>
            <div class="b15"><button class="btn btn-secondary" @click.prevent="this.insert('7')">7</button></div>
            <div class="b16"><button class="btn btn-secondary" @click.prevent="this.insert('8')">8</button></div>
            <div class="b17"><button class="btn btn-secondary" @click.prevent="this.insert('9')">9</button></div>
            <div class="b18"><button class="btn btn-info" @click.prevent="this.insert('-')">&minus;</button></div>
            <div class="b19"><button class="btn btn-secondary" @click.prevent="this.insert('csc()')">csc</button></div>
            <div class="b20"><button class="btn btn-secondary" @click.prevent="this.insert('sec()')">sec</button></div>
            <div class="b21"><button class="btn btn-secondary" @click.prevent="this.insert('cot()')">cot</button></div>
            <div class="b22"><button class="btn btn-light" @click.prevent="this.insert('ln()')">ln</button></div>
            <div class="b23"><button class="btn btn-light" @click.prevent="this.insert('log)=')">log<sub>10</sub></button></div>
            <div class="b24"><button class="btn btn-secondary" @click.prevent="this.insert('4')">4</button></div>
            <div class="b25"><button class="btn btn-secondary" @click.prevent="this.insert('5')">5</button></div>
            <div class="b26"><button class="btn btn-secondary" @click.prevent="this.insert('6')">6</button></div>
            <div class="b27"><button class="btn btn-info" @click.prevent="this.insert('+')">+</button></div>
            <div class="b28"><button class="btn btn-secondary" @click.prevent="this.insert('sinh()')">sinh</button></div>
            <div class="b29"><button class="btn btn-secondary" @click.prevent="this.insert('cosh()')">cosh</button></div>
            <div class="b30"><button class="btn btn-secondary" @click.prevent="this.insert('tanh()')">tanh</button></div>
            <div class="b31"><button class="btn btn-light" @click.prevent="this.insert('sqrt()')">&radic;</button></div>
            <div class="b32"><button class="btn btn-light" data-ins=""><sup><var>n</var></sup>&radic;</button></div>
            <div class="b33"><button class="btn btn-secondary" @click.prevent="this.insert('1')">1</button></div>
            <div class="b34"><button class="btn btn-secondary" @click.prevent="this.insert('2')">2</button></div>
            <div class="b35"><button class="btn btn-secondary" @click.prevent="this.insert('3')">3</button></div>
            <div class="b36"><button class="btn btn-secondary" @click.prevent="this.insert('asinh()')">sinh<sup>-1</sup></button></div>
            <div class="b37"><button class="btn btn-secondary" @click.prevent="this.insert('acosh()')">cosh<sup>-1</sup></button></div>
            <div class="b38"><button class="btn btn-secondary" @click.prevent="this.insert('atanh()')">tanh<sup>-1</sup></button></div>
            <div class="b39"><button class="btn btn-light" @click.prevent="this.insert('&pi;')">&pi;</button></div>
            <div class="b40"><button class="btn btn-light fst-italic" @click.prevent="this.insert('x')"><var>x</var></button></div>
            <div class="b41"><button class="btn btn-secondary" @click.prevent="this.insert('0')">0</button></div>
            <div class="b42"><button class="btn btn-secondary" @click.prevent="this.insert('.')">.</button></div>
            <div class="b43"><button class="btn btn-secondary" @click.prevent="this.insert('-')">(-)</button></div>
            <div class="b44"><button class="btn btn-primary fw-bold text-white" id="go" @click.prevent="this.Compute()" :disabled="this.isWorking">
                {{ this.$t("calc.go") }}
            </button></div>
        </div>
    </div>

    <div class="output" ref="output">
        <div id="waiting" class="mt-5">
            <Waves :show="this.isWorking"></Waves>
        </div>
        <div class="error text-center my-4" ref="error" v-if="this.RequestError !== null">
            <i class="fa-light fa-triangle-exclamation text-primary" style="font-size: 10rem"></i>
            <div class="fw-bold font-size-12 w-75 mx-auto mt-3">
                <div v-if="this.RequestError == -1">{{ this.$t("errors.timeout") }}</div>
                <div v-if="this.RequestError == -2">{{ this.$t("errors.server-error") }}</div>
            </div>
        </div>
        <div ref="share" class="position-relative d-flex justify-content-end mt-6 pt-3" v-if="this.solution.length && this.RequestError === null">
            <div class="share btn btn-primary btn-sm rounded-bottom-0 border-2 text-white small fw-bold d-inline-flex align-items-center me-4" role="button" @click="ShareLink()">{{ this.$t("msg.share-button") }}<i class="fal fa-share-nodes font-size-12 ms-2"></i></div>
        </div>
        <section class="solution position-relative" v-if="this.solution.length && this.RequestError === null">
            <div class="h5 font-slab">{{ this.$t("calc.h-derivative") }}</div>
            <div v-html="this.header"></div>
            <ActionButtons :formats="this.inputFormats" :expression="this.inputFormats['str']" id="Input"></ActionButtons>
            <hr />
            
            <div class="h5 font-slab mb-3">{{ this.$t("calc.h-step-solution") }}</div>
            <div v-html="this.solution" class="isteps"></div>

            <ActionButtons :formats="this.solutionFormats" :expression="this.solutionFormats['str']" id="Solution"></ActionButtons>
            
            <hr class="border-0 mt-3" />
            <Plotter :expression="this.inputParsed" :derivative="this.solutionParsed" :variable="this.variable"></Plotter>

            <hr />
            <button class="btn fw-bold btn-outline-dark d-flex align-items-center mx-auto mt-4" @click="this.NextDerivative()">
                <span>{{ this.$t("msg.nextdx") }}</span>
                <i class="ms-2 fal fa-wand-magic-sparkles font-size-10"></i>
            </button>
        </section>
    </div>

    <Modal :heading="this.$t('modals.share-solution')" :id="this.ShareLinkModalName" :noUp="true">
        <template v-slot:content>
            <p>{{ this.$t("msg.share-text") }}</p>
            <CopyBox :text="this.getShareLink"></CopyBox>
        </template>
        <template v-slot:footer>
            <button type="button" class="btn btn-dark btn-sm px-4" @click="this.closeModals()">
                {{ this.$t('modals.done-button') }}
            </button>
        </template>
    </Modal>

</template>

<script>
import { defineAsyncComponent } from 'vue';
import { Carousel, Slide } from './libs/vue3-carousel'
import { parse } from 'mathjs';
import Temml from 'temml';
import Modal from './components/Modal.vue';
//import Plotter from './components/Plotter.vue';
import Collapse from './components/Collapse.vue';
import Waves from './components/Waves.vue';
import CopyBox from './components/CopyBox.vue';
import ActionButtons from './components/ActionButtons.vue';
import axios from 'axios';
import qs from 'qs';
import './libs/vue3-carousel/dist/carousel.css';

export default {
    name: 'App',
    components: {
        Modal,
        Collapse,
        Carousel,
        Slide,
        ActionButtons,
        Waves,
        CopyBox,
        Plotter: defineAsyncComponent(() =>
            import('./components/Plotter.vue')
        ),
    },
    data() {
        return {
            cursorPos: 0,
            expression: "",
            // options
            variable: "x",
            showSteps: true,
            simplifyExpression: false,
            numDerivative: 1,
            // internal variables
            currentSlide: 0,
            validAlpha: ['a', 'b', 'c', 'd', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'v', 'w', 'x', 'y', 'z'],
            diffVariables: ["x","y","z","a","b","c","d","f","g","h","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z"],
            rerenderTimeout: null,
            TexDisplay: "",
            ErrorMsg: null,
            ErrorShow: false,
            RequestError: null,
            hasSyntaxError: false,
            showOptions: false,
            isWorking: false,
            solution: "",
            header: "",
            solutionFormats: [],
            inputFormats: [],
            inputParsed: "",
            solutionParsed: ""
        }
    },
    created() {
        // Close all modals when the escape key is pressed
        const onKeyDown = (e) => {
            // Escape key
            if (e.keyCode === 27) {
                this.emitter.emit("onCancel");
            }
        };
        
        document.addEventListener('keydown', onKeyDown);
    },
    mounted() {
        // Process hash parameters for link sharing...
        var hash = window.location.hash;
        if(hash) {
            hash = hash.substring(1);
            const parsed = qs.parse(hash);
            const defaults = {
                steps: true,
                simplify: false,
                v: "x"
            };

            var merged = {...defaults, ...parsed};
            
            merged.steps = !!merged.steps;
            merged.simplify = !!merged.simplify;

            this.showSteps = merged.steps;
            this.simplifyExpression = merged.simplify;

            if(this.diffVariables.includes(merged.v)) {
                this.variable = merged.v;
            }

            if("expr" in merged) {
                this.expression = merged.expr;
                this.Compute();
            }
        }
    },
    methods: {
        setCursor: function(pos) {
            pos = Math.min(Math.max(pos, 0), this.expression.length);
            this.cursorPos = pos;
        },
        insert: function(s) {
            if(this.isMobile) {
                this.expression = [
                                    this.expression.slice(0, this.cursorPos),
                                    s,
                                    this.expression.slice(this.cursorPos)
                                  ].join('');
                if(!!~s.indexOf("()")) {
                    this.setCursor(this.cursorPos + s.indexOf("()") + 1)
                } else {
                    this.setCursor(this.cursorPos + s.length);
                }

            } else {
                // Desktop version...
                this.insertTextAtCursorOrAppendToTextarea(s);
            }
        },
        delete() {
            if(this.isMobile) {
                if(this.cursorPos > 0 && this.expression.length) {
                    const cp = this.cursorPos - 1
                    this.expression = this.expression.slice(0, cp) + this.expression.slice(cp + 1);
                    this.setCursor(this.cursorPos - 1);
                }
            }
        },
        encloseVariables(expression) {
            expression = expression.replaceAll("pi", "(pi)");
            expression = expression.replaceAll("π", "(pi)");
            expression = expression.replaceAll("**", "^");
            // List of function names
            const functions = ['sin', 'cos', 'tan', 'log', 'exp', 'sqrt', 'abs', 'asin', 'acos', 'atan', 'sinh', 'cosh', 'tanh', 'asinh', 'acosh', 'atanh', 'csc', 'sec', 'cot', 'ln', 'pi', 'erf', 'gamma', 'coth', 'sech', 'csch', 'acoth', 'asech', 'acsch'].sort((a, b) => b.length - a.length);

            // Create a regex to match function names followed by (
            const functionRegex = new RegExp(`(${functions.join('|')})`, 'gi');

            // Split the expression by function names, preserving the split points
            const parts = expression.split(functionRegex);

            // Process each part to enclose standalone variables in parentheses
            const variableRegex = /[a-zA-Z_]/g;
            for (let i = 0; i < parts.length; i++) {
                if (!functions.includes(parts[i])) {
                    // Enclose variables with parentheses
                    parts[i] = parts[i].replace(variableRegex, (match) => `(${match})`);
                }
            }

            // Reassemble the expression by joining parts
            const resultExpression = parts.join('');

            return resultExpression;
        },
        SyntaxOkay(expr) {
            var exprM = expr;
            if(expr !== undefined) {
                exprM = this.expression;
            }

            if (exprM == undefined || exprM.length == 0) {
                return true;
            }

            try {
                exprM = this.encloseVariables(exprM);
                parse(exprM);

                return true;
            } catch (e) {
                return false;
            }
        },
        GetSyntaxError() {
            var exprM = this.expression;

            if (exprM == undefined || exprM.length == 0) {
                return {SyntaxOkay: true, ErrorMessage: null};
            }
            
            try {
                exprM = this.encloseVariables(exprM);
                parse(exprM);

                return {SyntaxOkay: true, ErrorMessage: null};
            } catch (e) {
                return {SyntaxOkay: false, ErrorMessage: e.toString()};
            }
        },
        ParseMath(expr) {
            var exprM = null;
            if(expr !== undefined) {
                exprM = this.expression;
            }

            if (exprM === undefined || !exprM.length) {
                return true;
            }

            try {
                exprM = encloseVariables(exprM);
                return parse(exprM).toString({parenthesis: 'auto'});
            } catch (e) {
                return null;
            }
        },
        insertTextAtCursorOrAppendToTextarea(text) {
            // Get the textarea element by its ID
            var textarea = this.$refs.textarea;

            if (textarea && textarea.tagName === 'TEXTAREA') {
                // Focus the textarea
                textarea.focus();

                var startPos = textarea.selectionStart;
                var endPos = textarea.selectionEnd;
                var selectedText = textarea.value.substring(startPos, endPos);
                var currentValue = textarea.value;

                // If text contains "()" and there is selected text
                if (text.includes('()') && selectedText) {
                    // Replace the "()" in the text with the selected text
                    text = text.replace('()', '(' + selectedText + ')');
                    textarea.value = currentValue.substring(0, startPos) + text + currentValue.substring(endPos);
                    startPos = startPos + text.indexOf('(') + 1;
                    endPos = startPos + selectedText.length;
                    textarea.setSelectionRange(startPos, endPos);
                } else {
                    if (startPos !== endPos) {
                        // If there is a selection, replace it with the text
                        textarea.value = currentValue.substring(0, startPos) + text + currentValue.substring(endPos);
                        startPos = startPos + text.length;
                    } else {
                        // Otherwise, insert the text at the cursor position or at the end if there's no cursor position
                        textarea.value = currentValue.substring(0, startPos) + text + currentValue.substring(startPos);
                        startPos = startPos + text.length;
                    }

                    // Set cursor position based on the presence of ()
                    if (text.includes('()')) {
                        var cursorPos = startPos - text.length + text.indexOf('(') + 1;
                        textarea.setSelectionRange(cursorPos, cursorPos);
                    } else {
                        // Set cursor at the end of the inserted text
                        textarea.setSelectionRange(startPos, startPos);
                    }
                }

                this.expression = textarea.value;
            } else {
                console.warn('No textarea element found with the specified ID.');
            }
        },
        deleteLastCharacterInTextarea() {
            // Get the textarea element by its ID
            var textarea = this.$refs.textarea;

            if (textarea) {
                // Focus the textarea
                textarea.focus();

                var start = textarea.selectionStart;
                var end = textarea.selectionEnd;
                var value = textarea.value;

                if (start === end && start > 0) {
                    // No text selected, delete the character before the cursor
                    var newText = value.substring(0, start - 1) + value.substring(end);
                    textarea.value = newText;

                    // Set cursor position
                    textarea.setSelectionRange(start - 1, start - 1);
                } else if (start !== end) {
                    // Text selected, delete the selected text
                    var newText = value.substring(0, start) + value.substring(end);
                    textarea.value = newText;

                    // Set cursor position
                    textarea.setSelectionRange(start, start);
                }
            } else {
                console.warn('No textarea element found.');
            }
        },
        getOrdinal(n) {
            let ord = 'th';

            if (n % 10 == 1 && n % 100 != 11) {
                ord = 'st';
            }
            else if (n % 10 == 2 && n % 100 != 12) {
                ord = 'nd';
            }
            else if (n % 10 == 3 && n % 100 != 13) {
                ord = 'rd';
            }

            return ord;
        },
        async Compute(expr) {
            const SyntaxCheck = this.GetSyntaxError();
            if(!SyntaxCheck.SyntaxOkay) {
                this.hasSyntaxError = !SyntaxCheck.SyntaxOkay;
                this.ErrorMsg = SyntaxCheck.ErrorMessage;
                this.ErrorShow = true;

                return;
            }

            var expressionToBeDerived = this.encloseVariables(this.expression);
            if(expr !== undefined) {
                expressionToBeDerived = this.encloseVariables(expr);
            }

            // Check for empty expression...
            if(expressionToBeDerived.length == 0) {
                return;
            }

            this.inputFormats    = [];
            this.solutionFormats = [];
            this.solution        = "";
            this.header          = "";
            this.inputParsed     = "";
            this.solutionParsed  = "";
            this.isLoading       = true;
            this.RequestError    = null;

            var t = this;

            axios.post(this.$api, qs.stringify({
                action:     "diff",
                expression: expressionToBeDerived,
                var:        this.variable,
                showsteps:  this.showSteps,
                simplify:   this.simplifyExpression,
                lang:       this.$i18n.locale
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                timeout: 14500
            }).then(function(response) {
                t.solution        = t.$mathml ? t.makeMathML(response.data["result"]) : response.data["result"];
                t.header          = t.$mathml ? t.makeMathML(response.data["header"]) : response.data["header"];
                t.inputFormats    = response.data["input"] || [];
                t.solutionFormats = response.data["solution"] || [];

                const formatter = function(mth) {
                    if(!mth) {
                        return mth;
                    }
                    mth = mth.replaceAll("**", "^");
                    return mth;
                };

                if(response && response.hasOwnProperty("data") && response.data) {
                    if(response.data.hasOwnProperty("input") && response.data.input && response.data.input.hasOwnProperty("str") && response.data.input.str) {
                        t.inputParsed = response.data["input"]["str"];
                    }
                    if(response.data.hasOwnProperty("solution") && response.data.solution && response.data.solution.hasOwnProperty("str") && response.data.solution.str) {
                        t.solutionParsed  = response.data["solution"]["str"];
                    }
                }

                t.$store.commit('setVariable', t.variable);
                t.$store.commit('setSolution', t.solutionParsed);
                t.$store.commit('setExpression', t.inputParsed);

                if(!t.$mathml) {
                    window.setTimeout(function() {
                        MathJax.typesetPromise();
                    }, 100);
                }
            }).catch(error => {
                console.log("error", error);
                if(!!~error.message.indexOf("timeout")) {
                    t.RequestError = -1;
                } else {
                    t.RequestError = -2;
                }

                window.setTimeout(function() {
                    t.$refs.error.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
                }, 100);

            }).finally(i => t.isLoading = false);

        },
        NextDerivative() {
            this.expression = this.solutionParsed;
            this.Compute(this.solutionParsed);
        },

        closeModals() {
            this.emitter.emit("onCancel");
        },

        ShareLink() {
            this.emitter.emit("Show" + this.ShareLinkModalName);
        },
        makeMathML(latex) {
            latex = latex.replaceAll("^{}", "");
            const t = this;
            latex = latex.replace(/\$\$\s*(.*?)\s*\$\$/gims, (match, p1) => {
                return Temml.renderToString(p1, { displayMode: true });
            });
            latex = latex.replace(/\\\(\s*(.*?)\s*\\\)/gims, (match, p1) => {
                return Temml.renderToString(p1, { displayMode: false });
            });
            latex = latex.replace(/\\\[\s*(.*?)\s*\\\]/gims, (match, p1) => {
                return Temml.renderToString(p1, { displayMode: false });
            });

            return latex;
        }
    },
    computed: {
        getChars() {
            return this.expression.length ? [...this.expression] : [];
        },
        isMobile() {
            var win = window,
                doc = document,
                docElem = doc.documentElement,
                body = doc.getElementsByTagName('body')[0];
            const width = win.innerWidth || docElem.clientWidth || body.clientWidth;

            return width < 768;
        },
        getShareLink() {
            const path = (window.location.host + "/" + window.location.pathname).replaceAll("//", "/");
            const base = "https://" + path;//"https://derivative-calculator.org/";

            let defaults = {
                steps: true,
                simplify: false,
                v: "x"
            };

            let current = {
                steps: this.showSteps,
                simplify: this.simplifyExpression,
                v: this.variable
            };

            var params = {
                expr: this.expression
            };

            for(const [key, value] of Object.entries(defaults)) {
                if(defaults[key] != current[key]) {
                    params[key] = current[key];
                }
            }

            return base + "#" + qs.stringify(params);
        },
        Tex() {
            var out = "\\dfrac{\\mathrm{d}}{\\mathrm{d}\\," + this.variable + "}\\;\\displaystyle \\left [ \\,{ @@@ \\vphantom{ {@@@}^{42} }}\\right ]";

            var exprParsed = "";
            var exprM = this.expression;

            if (exprM == undefined || !exprM.length) {
                exprParsed = "\\cdots ";
            } else {
                try {
                    exprM = this.encloseVariables(exprM);
                    const mp = parse(exprM);
                    exprParsed = mp.toTex({ implicit: 'show', parenthesis: "auto" });
                } catch (e) {
                    exprParsed = "\\cdots ";
                    this.ErrorMsg = e.toString();
                }
            }

            out = out.replaceAll("@@@", exprParsed);
            out = "\\[ " + out + " \\]";

            return out;
        },
        ExprSyntaxOkay() {
            return this.SyntaxOkay();
        },
        isLoading: {
            get() {
                return this.$store.getters.isLoading;
            },
            set(i) {
                //this.isWorking = i;
                this.$store.commit('setLoading', i);
            }
        },
        ShareLinkModalName() {
            return "ShareLinkModal";
        }
    },
    watch: {
        isLoading(newValue, oldValue) {
            if(newValue == true || !this.solution.length) {
                var t = this;
                window.setTimeout(function() {
                    t.$refs.output && t.$refs.output.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
                }, 100);
            }
        },
        solution(newValue, oldValue) {
            if(!newValue.length) {
                return;
            }
            var t = this;
            window.setTimeout(function() {
                t.$refs.share && t.$refs.share.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
            }, 100);
        },
        expression(oldExrp, newexpr) {
            if(this.rerenderTimeout) {
                window.clearTimeout(this.rerenderTimeout)
            }
            var t = this;
            this.rerenderTimeout = window.setTimeout(function() {
                if(t.$mathml) {
                    t.TexDisplay = t.makeMathML(t.Tex);
                } else {
                    t.TexDisplay = t.Tex;
                    window.setTimeout(function() {
                        MathJax.typesetPromise();
                    }, 25);
                }

                t.rerenderTimeout = null;
            }, 500);

            const SyntaxCheck = this.GetSyntaxError();
            
            this.hasSyntaxError = !SyntaxCheck.SyntaxOkay;
            this.ErrorMsg = SyntaxCheck.ErrorMessage;

            if(SyntaxCheck.SyntaxOkay && this.ErrorShow) {
                this.ErrorShow = false;
            }
        }
    }
}
</script>

<style lang="scss">
.carousel {
    max-width: calc(100vw - 3rem);
}

@media (min-width: 576px) {
    .carousel {
        width: calc(540px - 1.5rem) !important;
    }
}
.m1 {
    margin: 0 1px;
}
#math-preview {
    mjx-container {
        color: black;
    }

    math {
        top: 1rem;
    }
}
</style>
