<template>
    <div class="collapsible-content" :class="{'d-none': this.isLoading, show: this.show, done: this.show && this.hasCompleted}" ref="content">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'Collapse',

    props: {
        show: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            hasCompleted: false,
            isLoading: true
        }
    },

    watch: {
        show(newVal) {
            this.setMaxHeight(newVal);
        }
    },

    mounted() {
        this.setMaxHeight(this.show);
        this.isLoading = false;
    },

    methods: {
        setMaxHeight(show) {
            const content = this.$refs.content;
            var t = this;
            if (show) {
                content.style.maxHeight = content.scrollHeight + 'px';
                window.setTimeout(function() {
                    t.hasCompleted = true;
                }, 500);
                
            } else {
                this.hasCompleted = false;
                content.style.maxHeight = content.scrollHeight + 'px';
                window.setTimeout(i => content.style.maxHeight = '0px', 50);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.collapsible-content {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.35s ease;

    &.done {
        max-height: fit-content !important;
    }
}
</style>
