<template>
    <div class="calc d-flex flex-column">
        <div class="input sm flex-column justify-content-between align-items-start rounded-top-4 p-3">
            <div class="preview position-absolute" id="math-preview" v-if="this.$mathml" v-html="this.TexDisplay"></div>
            <div class="preview small" id="math-preview" v-else>{{ this.TexDisplay }}</div>
            <div class="d-flex justify-content-center align-items-end cnt sm rounded-top-4 rounded-bottom-0 border border-4 border-bottom-0" @click="this.$refs.textareacompare.focus()">
                <textarea name="text" id="text" spellcheck="false" autocomplete="off" class="font-sizer-18 fw-bold w-100 text-center text p-3 border-0 rounded-top-4 rounded-bottom-1 d-none d-md-block" row="1" data-autoresize :placeholder="this.$t('calc.placeholder_compare')" v-model="this.expression" ref="textareacompare" @keydown.enter.prevent="this.Compare()"></textarea>
                <div class="d-flex flex-wrap d-md-none text-center font-sizer-16 fw-bold font-monospace mobile-text p-3">
                    <span v-for="(chr, index) in this.getChars" @click.prevent="this.setCursor(index)" :class="{cursor: this.cursorPos == index}">{{ chr }}</span>
                    <span :class="{cursor: this.cursorPos == this.expression.length}" @click.prevent="this.setCursor(this.expression.length)">&nbsp;</span>
                </div>
            </div>
        </div>
        <div class="options p-2 rounded-bottom-3 small">
            <div class="row d-flex justify-content-end">
                <div class="col align-items-center d-flex ps-3">
                    <i class="fal fa-gear font-size-12 text-dark"></i>
                </div>

                <div class="col-3 justify-content-center align-items-center d-flex ps-3" @click="this.ErrorShow = !this.ErrorShow">
                    <i class="fal fa-triangle-exclamation font-size-12 text-dyellow" v-if="this.hasSyntaxError"></i>
                </div>
            </div>
        </div>
        <div class="buttons-mobile pt-2 d-grid d-lg-none">
            <div class="row bctn gx-2">
                <div class="col-3"><button class="btn btn-dark2 cen" @click.prevent="this.setCursor(0)"><i class="far fa-arrow-left-to-bracket"></i></button></div>
                <div class="col-3"><button class="btn btn-dark2 cen" @click.prevent="this.setCursor(this.cursorPos - 1)"><i class="far fa-arrow-left-long"></i></button></div>
                <div class="col-3"><button class="btn btn-dark2 cen" @click.prevent="this.setCursor(this.cursorPos + 1)"><i class="far fa-arrow-right-long"></i></button></div>
                <div class="col-3"><button class="btn btn-dark2 cen" @click.prevent="this.setCursor(this.expression.length)"><i class="far fa-arrow-right-to-bracket"></i></button></div> 
            </div>
            <div class="row bctn low gx-2 mt-3">
                <div class="col-3">
                    <button :class="{'btn-dyellow': this.currentSlide == 0, 'btn-outline-light': this.currentSlide != 0, 'text-body-tertiary': this.currentSlide != 0}" class="rounded-pill btn p-0 d-flex justify-content-center align-items-center" @click.prevent="this.currentSlide = 0">
                        <i class="far fa-hashtag"></i>
                    </button>
                </div>
                <div class="col-3">
                    <button :class="{'btn-dyellow': this.currentSlide == 1, 'btn-outline-light': this.currentSlide != 1, 'text-body-tertiary': this.currentSlide != 1}" class="rounded-pill btn p-0 d-flex justify-content-center align-items-center" @click.prevent="this.currentSlide = 1">
                        <i class="far fa-square-root"></i>
                    </button>
                </div>
                <div class="col-3">
                    <button :class="{'btn-dyellow': this.currentSlide == 2, 'btn-outline-light': this.currentSlide != 2, 'text-body-tertiary': this.currentSlide != 2}" class="rounded-pill btn p-0 d-flex justify-content-center align-items-center" @click.prevent="this.currentSlide = 2">
                        <i class="far fa-wave-sine"></i>
                    </button>
                </div> 
                <div class="col-3">
                    <button :class="{'btn-dyellow': this.currentSlide == 3, 'btn-outline-light': this.currentSlide != 3, 'text-body-tertiary': this.currentSlide != 3}" class="rounded-pill btn p-0 d-flex justify-content-center align-items-center" @click.prevent="this.currentSlide = 3">
                        <i class="far fa-a font-size-9"></i>
                    </button>
                </div>
            </div>

            <div class="slider-wrapper">
            <ul class="slides-container" ref="slidesContainer">
                <li class="slide" key="cnum">
                    <div class="grid5 mt-3 w-100">
                        <div class="b1"><button class="btn btn-info" @click.prevent="this.insert('()/()')"><sup><var>u</var></sup>/<sub><var>v</var></sub></button></div>
                        <div class="b2"><button class="btn btn-info" @click.prevent="this.insert('/')">&div;</button></div>
                        <div class="b3"><button class="btn btn-info" @click.prevent="this.insert('*')">&times;</button></div>
                        <div class="b4"><button class="btn btn-info delete" id="delete" @click.prevent="this.delete()"><i class="fa-solid fa-delete-left"></i></button></div>
                        <div class="b5"><button class="btn btn-secondary" @click.prevent="this.insert('7')">7</button></div>
                        <div class="b6"><button class="btn btn-secondary" @click.prevent="this.insert('8')">8</button></div>
                        <div class="b7"><button class="btn btn-secondary" @click.prevent="this.insert('9')">9</button></div>
                        <div class="b8"><button class="btn btn-info" @click.prevent="this.insert('-')">&minus;</button></div>
                        <div class="b9"><button class="btn btn-secondary" @click.prevent="this.insert('4')">4</button></div>
                        <div class="b10"><button class="btn btn-secondary" @click.prevent="this.insert('5')">5</button></div>
                        <div class="b11"><button class="btn btn-secondary" @click.prevent="this.insert('6')">6</button></div>
                        <div class="b12"><button class="btn btn-info" @click.prevent="this.insert('+')">+</button></div>
                        <div class="b13"><button class="btn btn-secondary" @click.prevent="this.insert('1')">1</button></div>
                        <div class="b14"><button class="btn btn-secondary" @click.prevent="this.insert('2')">2</button></div>
                        <div class="b15"><button class="btn btn-secondary" @click.prevent="this.insert('3')">3</button></div>
                        <div class="b16"><button class="btn btn-secondary" @click.prevent="this.insert('0')">0</button></div>
                        <div class="b17"><button class="btn btn-secondary" @click.prevent="this.insert('.')">.</button></div>
                        <div class="b18"><button class="btn btn-secondary" @click.prevent="this.insert('-')">(-)</button></div>
                        <div class="b19"><button class="btn btn-success fw-bold text-white" id="go" @click.prevent="this.Compare()" :disabled="this.isWorking">
                            <div class="d-flex flex-column">
                                <i class="fa-light fa-check font-size-12"></i>
                                <div>{{ this.$t("modals.check") }}</div>
                            </div>
                        </button></div>
                    </div>
                </li>
                
                <li class="slide" key="cfunc">
                    <div class="grid3 w-100 mt-3">
                        <div class="b1"><button class="btn btn-secondary" @click.prevent="this.insert('(')">(</button></div>
                        <div class="b2"><button class="btn btn-secondary" @click.prevent="this.insert('x')"><var>x</var></button></div>
                        <div class="b3"><button class="btn btn-secondary" @click.prevent="this.insert(')')">)</button></div>
                        <div class="b4"><button class="btn btn-secondary" @click.prevent="this.insert('^2')"><var>x</var><sup class="m1">2</sup></button></div>
                        <div class="b5"><button class="btn btn-secondary" @click.prevent="this.insert('^3')"><var>x</var><sup class="m1">3</sup></button></div>
                        <div class="b6"><button class="btn btn-secondary" @click.prevent="this.insert('^()')"><var>x</var><sup class="m1"><var>n</var></sup></button></div>
                        <div class="b7"><button class="btn btn-secondary" @click.prevent="this.insert('sqrt()')"><sup>2</sup>&radic;</button></div>
                        <div class="b8"><button class="btn btn-secondary" @click.prevent="this.insert('^(1/3)')"><sup>3</sup>&radic;</button></div>
                        <div class="b9"><button class="btn btn-secondary" @click.prevent="this.insert('^(1/)')"><sup><var>n</var></sup>&radic;</button></div>
                        <div class="b10"><button class="btn btn-secondary" @click.prevent="this.insert('ln()')">ln</button></div>
                        <div class="b11"><button class="btn btn-secondary" @click.prevent="this.insert('log()')">log<sub>10</sub></button></div>
                        <div class="b12"><button class="btn btn-secondary" @click.prevent="this.insert('i')"><var>i</var></button></div>
                        <div class="b13"><button class="btn btn-secondary" @click.prevent="this.insert('e')"><var>e</var></button></div>
                        <div class="b14"><button class="btn btn-secondary" @click.prevent="this.insert('e^()')"><var>e</var><sup class="m1"><var>x</var></sup></button></div>
                        <div class="b15"><button class="btn btn-secondary" @click.prevent="this.insert('pi')">&pi;</button></div>
                    </div>
                </li>

                <li class="slide" key="ctrig">
                    <div class="grid3 w-100 mt-3">
                        <div class="b1"><button class="btn btn-secondary" @click.prevent="this.insert('sin()')">sin</button></div>
                        <div class="b2"><button class="btn btn-secondary" @click.prevent="this.insert('cos()')">cos</button></div>
                        <div class="b3"><button class="btn btn-secondary" @click.prevent="this.insert('tan()')">tan</button></div>
                        <div class="b4"><button class="btn btn-secondary" @click.prevent="this.insert('asin()')">sin<sup>-1</sup></button></div>
                        <div class="b5"><button class="btn btn-secondary" @click.prevent="this.insert('acos()')">cos<sup>-1</sup></button></div>
                        <div class="b6"><button class="btn btn-secondary" @click.prevent="this.insert('atan()')">tan<sup>-1</sup></button></div>
                        <div class="b7"><button class="btn btn-secondary" @click.prevent="this.insert('csc()')">csc</button></div>
                        <div class="b8"><button class="btn btn-secondary" @click.prevent="this.insert('sec()')">sec</button></div>
                        <div class="b9"><button class="btn btn-secondary" @click.prevent="this.insert('cot()')">cot</button></div>
                        <div class="b10"><button class="btn btn-secondary" @click.prevent="this.insert('sinh()')">sinh</button></div>
                        <div class="b11"><button class="btn btn-secondary" @click.prevent="this.insert('cosh()')">cosh</button></div>
                        <div class="b12"><button class="btn btn-secondary" @click.prevent="this.insert('tanh()')">tanh</button></div>
                        <div class="b13"><button class="btn btn-secondary" @click.prevent="this.insert('asinh()')">sinh<sup>-1</sup></button></div>
                        <div class="b14"><button class="btn btn-secondary" @click.prevent="this.insert('acosh()')">cosh<sup>-1</sup></button></div>
                        <div class="b15"><button class="btn btn-secondary" @click.prevent="this.insert('atanh()')">tanh<sup>-1</sup></button></div>
                    </div>
                </li>

                <li class="slide" key="calpha">
                    <div class="grid-alpha mt-3 w-100">
                        <div v-for="(alpha, index) in this.validAlpha" :key="index" :class="'b' + (index+1)">
                            <button class="btn btn-secondary" @click.prevent="this.insert(alpha)">
                                <var class="font-size-13">{{ alpha }}</var>
                            </button>
                        </div>
                    </div>
                </li>
            </ul>
            </div>

        </div>
        <div class="buttons pt-2 d-none d-lg-grid">
            <div class="b1"><button class="btn btn-secondary" @click.prevent="this.insert('sin()')">sin</button></div>
            <div class="b2"><button class="btn btn-secondary" @click.prevent="this.insert('cos()')">cos</button></div>
            <div class="b3"><button class="btn btn-secondary" @click.prevent="this.insert('tan()')">tan</button></div>
            <div class="b4"><button class="btn btn-light" @click.prevent="this.insert('(')">(</button></div>
            <div class="b5"><button class="btn btn-light" @click.prevent="this.insert(')')">)</button></div>
            <div class="b6"><button class="btn btn-info" @click.prevent="this.insert('()/()')"><sup><var>u</var></sup>/<sub><var>v</var></sub></button></div>
            <div class="b7"><button class="btn btn-info" @click.prevent="this.insert('/')">&div;</button></div>
            <div class="b8"><button class="btn btn-info" @click.prevent="this.insert('*')">&times;</button></div>
            <div class="b9"><button class="btn btn-info delete" @click.prevent="this.deleteLastCharacterInTextarea()"><i class="fa-solid fa-delete-left"></i></button></div>
            <div class="b10"><button class="btn btn-secondary" @click.prevent="this.insert('asin()')">sin<sup>-1</sup></button></div>
            <div class="b11"><button class="btn btn-secondary" @click.prevent="this.insert('acos()')">cos<sup>-1</sup></button></div>
            <div class="b12"><button class="btn btn-secondary" @click.prevent="this.insert('atan()')">tan<sup>-1</sup></button></div>
            <div class="b13"><button class="btn btn-light" @click.prevent="this.insert('^()')"><var style="margin-right:1px">x</var><sup><var>n</var></sup></button></div>
            <div class="b14"><button class="btn btn-light" @click.prevent="this.insert('e^()')"><var style="margin-right:1px">e</var><sup><var>x</var></sup></button></div>
            <div class="b15"><button class="btn btn-secondary" @click.prevent="this.insert('7')">7</button></div>
            <div class="b16"><button class="btn btn-secondary" @click.prevent="this.insert('8')">8</button></div>
            <div class="b17"><button class="btn btn-secondary" @click.prevent="this.insert('9')">9</button></div>
            <div class="b18"><button class="btn btn-info" @click.prevent="this.insert('-')">&minus;</button></div>
            <div class="b19"><button class="btn btn-secondary" @click.prevent="this.insert('csc()')">csc</button></div>
            <div class="b20"><button class="btn btn-secondary" @click.prevent="this.insert('sec()')">sec</button></div>
            <div class="b21"><button class="btn btn-secondary" @click.prevent="this.insert('cot()')">cot</button></div>
            <div class="b22"><button class="btn btn-light" @click.prevent="this.insert('ln()')">ln</button></div>
            <div class="b23"><button class="btn btn-light" @click.prevent="this.insert('log)=')">log<sub>10</sub></button></div>
            <div class="b24"><button class="btn btn-secondary" @click.prevent="this.insert('4')">4</button></div>
            <div class="b25"><button class="btn btn-secondary" @click.prevent="this.insert('5')">5</button></div>
            <div class="b26"><button class="btn btn-secondary" @click.prevent="this.insert('6')">6</button></div>
            <div class="b27"><button class="btn btn-info" @click.prevent="this.insert('+')">+</button></div>
            <div class="b28"><button class="btn btn-secondary" @click.prevent="this.insert('sinh()')">sinh</button></div>
            <div class="b29"><button class="btn btn-secondary" @click.prevent="this.insert('cosh()')">cosh</button></div>
            <div class="b30"><button class="btn btn-secondary" @click.prevent="this.insert('tanh()')">tanh</button></div>
            <div class="b31"><button class="btn btn-light" @click.prevent="this.insert('sqrt()')">&radic;</button></div>
            <div class="b32"><button class="btn btn-light" data-ins=""><sup><var>n</var></sup>&radic;</button></div>
            <div class="b33"><button class="btn btn-secondary" @click.prevent="this.insert('1')">1</button></div>
            <div class="b34"><button class="btn btn-secondary" @click.prevent="this.insert('2')">2</button></div>
            <div class="b35"><button class="btn btn-secondary" @click.prevent="this.insert('3')">3</button></div>
            <div class="b36"><button class="btn btn-secondary" @click.prevent="this.insert('asinh()')">sinh<sup>-1</sup></button></div>
            <div class="b37"><button class="btn btn-secondary" @click.prevent="this.insert('acosh()')">cosh<sup>-1</sup></button></div>
            <div class="b38"><button class="btn btn-secondary" @click.prevent="this.insert('atanh()')">tanh<sup>-1</sup></button></div>
            <div class="b39"><button class="btn btn-light" @click.prevent="this.insert('&pi;')">&pi;</button></div>
            <div class="b40"><button class="btn btn-light fst-italic" @click.prevent="this.insert('x')"><var>x</var></button></div>
            <div class="b41"><button class="btn btn-secondary" @click.prevent="this.insert('0')">0</button></div>
            <div class="b42"><button class="btn btn-secondary" @click.prevent="this.insert('.')">.</button></div>
            <div class="b43"><button class="btn btn-secondary" @click.prevent="this.insert('-')">(-)</button></div>
            <div class="b44"><button class="btn btn-success fw-bold text-white" id="go" @click.prevent="this.Compare()" :disabled="this.isWorking">
                <div class="d-flex flex-column">
                    <i class="fa-light fa-check font-size-12"></i>
                    <div>{{ this.$t("modals.check") }}</div>
                </div>
            </button></div>
        </div>
    </div>
</template>

<script>
import { parse } from 'mathjs';
import Temml from 'temml';

export default {
    name: 'EqualityChecker',

    props: {
        parent: String
    },

    data() {
        return {
            cursorPos: 0,
            expression: "",
            // options
            variable: "x",
            showSteps: true,
            simplifyExpression: false,
            validAlpha: ['a', 'b', 'c', 'd', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'v', 'w', 'x', 'y', 'z'],
            // internal variables
            currentSlide: 2,
            rerenderTimeout: null,
            TexDisplay: "",
            ErrorMsg: null,
            ErrorShow: false,
            RequestError: null,
            hasSyntaxError: false,
            showOptions: false,
            isWorking: false,
            solution: "",
            header: "",
            solutionFormats: [],
            inputFormats: [],
            inputParsed: "",
            solutionParsed: "",
            // Carousel variables...
            startX: 0,
            currentX: 0,
            endX: 0,
            swipeThreshold: 50, // Threshold for complete swipe
            revealThreshold: 10, // Threshold for revealing the next slide
            // Carousel variables...
            touchstartX: 0,
            touchendX: 0
        }
    },

    mounted() {
        this.ReRender();

        const t = this;
        window.setTimeout(function() {
            t.currentSlide = 0;
        }, 1000);

        this.$refs.slidesContainer.addEventListener("touchstart", this.OnTouchstart, { passive: true });
        this.$refs.slidesContainer.addEventListener("touchend", this.OnTouchend, { passive: true });
        this.$refs.slidesContainer.addEventListener("touchmove", this.OnTouchmove, { passive: true });

        window.addEventListener("resize", this.OnResize, { passive: true });
    },

    methods: {
        OnTouchstart: function(e) {
            this.touchstartX = e.changedTouches[0].screenX;
            console.log(e);
        },
        OnTouchmove: function(e) {
            const slideWidth = this.$refs.slidesContainer.querySelector(".slide").clientWidth;

            this.$refs.slidesContainer.scroll({
                top: 0,
                left: slideWidth * this.currentSlide + (this.touchstartX - e.changedTouches[0].screenX)
            });
        },
        OnTouchend: function(e) {
            this.touchendX = e.changedTouches[0].screenX;
            var slideCalculated = -1;

            if(Math.abs(this.touchendX - this.touchstartX) > 75/2) {
                if (this.touchendX < this.touchstartX) {
                    slideCalculated = Math.min(Math.max(this.currentSlide + 1, 0), 3);
                } else {
                    slideCalculated = Math.min(Math.max(this.currentSlide - 1, 0), 3);
                }
            }

            if(slideCalculated == this.currentSlide) {
                this.RecalculateSlide();
            } else {
                if(slideCalculated != -1) {
                    this.currentSlide = slideCalculated;
                } else {
                    this.RecalculateSlide();
                }
            }
        },
        OnResize: function(e) {
            console.log(e);
            this.RecalculateSlide();
        },
        RecalculateSlide: function() {
            const slideWidth = this.$refs.slidesContainer.querySelector(".slide").clientWidth;

            this.$refs.slidesContainer.scroll({
                top: 0,
                left: slideWidth * this.currentSlide,
                behavior: "smooth"
            });
        },

        ReRender: function() {
            const t = this;
            window.setTimeout(function() {
                if(t.$mathml) {
                    t.TexDisplay = t.makeMathML(t.Tex);

                } else {
                    t.TexDisplay = t.Tex;
                    window.setTimeout(function() {
                        MathJax.typesetPromise();
                    }, 25);
                }
            }, 125);
        },
        setCursor: function(pos) {
            pos = Math.min(Math.max(pos, 0), this.expression.length);
            this.cursorPos = pos;
        },
        insert: function(s) {
            if(this.isMobile) {
                this.expression = [
                                    this.expression.slice(0, this.cursorPos),
                                    s,
                                    this.expression.slice(this.cursorPos)
                                  ].join('');
                if(!!~s.indexOf("()")) {
                    this.setCursor(this.cursorPos + s.indexOf("()") + 1)
                } else {
                    this.setCursor(this.cursorPos + s.length);
                }

            } else {
                // Desktop version...
                this.insertTextAtCursorOrAppendToTextarea(s);
            }
        },
        delete() {
            if(this.isMobile) {
                if(this.cursorPos > 0 && this.expression.length) {
                    const cp = this.cursorPos - 1
                    this.expression = this.expression.slice(0, cp) + this.expression.slice(cp + 1);
                    this.setCursor(this.cursorPos - 1);
                }
            }
        },
        encloseVariables(expression) {
            expression = expression.replaceAll("pi", "(pi)");
            expression = expression.replaceAll("π", "(pi)");
            expression = expression.replaceAll("**", "^");
            // List of function names
            const functions = ['sin', 'cos', 'tan', 'log', 'exp', 'sqrt', 'abs', 'asin', 'acos', 'atan', 'sinh', 'cosh', 'tanh', 'asinh', 'acosh', 'atanh', 'csc', 'sec', 'cot', 'ln', 'pi', 'erf', 'gamma', 'coth', 'sech', 'csch', 'acoth', 'asech', 'acsch'].sort((a, b) => b.length - a.length);
            
            // Create a regex to match function names followed by (
            const functionRegex = new RegExp(`(${functions.join('|')})`, 'gi');

            // Split the expression by function names, preserving the split points
            const parts = expression.split(functionRegex);

            // Process each part to enclose standalone variables in parentheses
            const variableRegex = /[a-zA-Z_]/g;
            for (let i = 0; i < parts.length; i++) {
                if (!functions.includes(parts[i])) {
                    // Enclose variables with parentheses
                    parts[i] = parts[i].replace(variableRegex, (match) => `(${match})`);
                }
            }

            // Reassemble the expression by joining parts
            const resultExpression = parts.join('');

            return resultExpression;
        },
        SyntaxOkay(expr) {
            var exprM = expr;
            if(expr !== undefined) {
                exprM = this.expression;
            }

            if (exprM == undefined || exprM.length == 0) {
                return true;
            }

            try {
                exprM = this.encloseVariables(exprM);
                parse(exprM);

                return true;
            } catch (e) {
                return false;
            }
        },
        GetSyntaxError() {
            var exprM = this.expression;

            if (exprM == undefined || exprM.length == 0) {
                return {SyntaxOkay: true, ErrorMessage: null};
            }
            
            try {
                exprM = this.encloseVariables(exprM);
                parse(exprM);

                return {SyntaxOkay: true, ErrorMessage: null};
            } catch (e) {
                return {SyntaxOkay: false, ErrorMessage: e.toString()};
            }
        },
        ParseMath(expr) {
            var exprM = null;
            if(expr !== undefined) {
                exprM = this.expression;
            }

            if (exprM === undefined || !exprM.length) {
                return true;
            }

            try {
                exprM = encloseVariables(exprM);
                return parse(exprM).toString({parenthesis: 'auto'});
            } catch (e) {
                return null;
            }
        },
        insertTextAtCursorOrAppendToTextarea(text) {
            // Get the textarea element by its ID
            var textarea = this.$refs.textareacompare;

            if (textarea && textarea.tagName === 'TEXTAREA') {
                // Focus the textarea
                textarea.focus();

                var startPos = textarea.selectionStart;
                var endPos = textarea.selectionEnd;
                var selectedText = textarea.value.substring(startPos, endPos);
                var currentValue = textarea.value;

                // If text contains "()" and there is selected text
                if (text.includes('()') && selectedText) {
                    // Replace the "()" in the text with the selected text
                    text = text.replace('()', '(' + selectedText + ')');
                    textarea.value = currentValue.substring(0, startPos) + text + currentValue.substring(endPos);
                    startPos = startPos + text.indexOf('(') + 1;
                    endPos = startPos + selectedText.length;
                    textarea.setSelectionRange(startPos, endPos);
                } else {
                    if (startPos !== endPos) {
                        // If there is a selection, replace it with the text
                        textarea.value = currentValue.substring(0, startPos) + text + currentValue.substring(endPos);
                        startPos = startPos + text.length;
                    } else {
                        // Otherwise, insert the text at the cursor position or at the end if there's no cursor position
                        textarea.value = currentValue.substring(0, startPos) + text + currentValue.substring(startPos);
                        startPos = startPos + text.length;
                    }

                    // Set cursor position based on the presence of ()
                    if (text.includes('()')) {
                        var cursorPos = startPos - text.length + text.indexOf('(') + 1;
                        textarea.setSelectionRange(cursorPos, cursorPos);
                    } else {
                        // Set cursor at the end of the inserted text
                        textarea.setSelectionRange(startPos, startPos);
                    }
                }

                this.expression = textarea.value;
            } else {
                console.warn('No textarea element found with the specified ID.');
            }
        },
        deleteLastCharacterInTextarea() {
            // Get the textarea element by its ID
            var textarea = this.$refs.textareacompare;

            if (textarea) {
                // Focus the textarea
                textarea.focus();

                var start = textarea.selectionStart;
                var end = textarea.selectionEnd;
                var value = textarea.value;

                if (start === end && start > 0) {
                    // No text selected, delete the character before the cursor
                    var newText = value.substring(0, start - 1) + value.substring(end);
                    textarea.value = newText;

                    // Set cursor position
                    textarea.setSelectionRange(start - 1, start - 1);
                } else if (start !== end) {
                    // Text selected, delete the selected text
                    var newText = value.substring(0, start) + value.substring(end);
                    textarea.value = newText;

                    // Set cursor position
                    textarea.setSelectionRange(start, start);
                }
            } else {
                console.warn('No textarea element found.');
            }
        },

        Compare() {
            this.emitter.emit("Compare", {
                parent: this.parent,
                expression: this.expression,
                comparator: this.getComparator["str"]
            });

            // Close the modal...
            this.emitter.emit("onCancel");
        },

        makeMathML(latex) {
            latex = latex.replaceAll("^{}", "");
            const t = this;
            latex = latex.replace(/\$\$\s*(.*?)\s*\$\$/gims, (match, p1) => {
                return Temml.renderToString(p1, { displayMode: true });
            });
            latex = latex.replace(/\\\(\s*(.*?)\s*\\\)/gims, (match, p1) => {
                return Temml.renderToString(p1, { displayMode: false });
            });
            latex = latex.replace(/\\\[\s*(.*?)\s*\\\]/gims, (match, p1) => {
                return Temml.renderToString(p1, { displayMode: false });
            });

            return latex;
        }



    },

    computed: {
        getChars() {
            return this.expression.length ? [...this.expression] : [];
        },
        isMobile() {
            var win = window,
                doc = document,
                docElem = doc.documentElement,
                body = doc.getElementsByTagName('body')[0];
            const width = win.innerWidth || docElem.clientWidth || body.clientWidth;

            return width < 768;
        },
        Tex() {
            var out = " \\stackrel{?}{=} @@@ ";
            if(this.getComparator && "latex" in this.getComparator) {
                out = this.getComparator["latex"] + out;
            }

            var exprParsed = "";
            var exprM = this.expression;

            if (exprM == undefined || !exprM.length) {
                exprParsed = "\\cdots ";
            } else {
                try {
                    exprM = this.encloseVariables(exprM);
                    const mp = parse(exprM);
                    exprParsed = mp.toTex({ implicit: 'show', parenthesis: "auto" });
                } catch (e) {
                    exprParsed = "\\cdots ";
                    this.ErrorMsg = e.toString();
                }
            }

            out = out.replaceAll("@@@", exprParsed);
            out = "\\[ " + out + " \\]";

            return out;
        },
        getComparator() {
            return this.$store.getters.getComparator;
        },
    },

    watch: {
        expression(oldExrp, newexpr) {
            if(this.rerenderTimeout) {
                window.clearTimeout(this.rerenderTimeout)
            }
            var t = this;
            this.rerenderTimeout = window.setTimeout(function() {
                if(t.$mathml) {
                    t.TexDisplay = t.makeMathML(t.Tex);
                } else {
                    t.TexDisplay = t.Tex;

                    window.setTimeout(function() {
                        MathJax.typesetPromise();
                    }, 25);
                }

                t.rerenderTimeout = null;
            }, 500);

            const SyntaxCheck = this.GetSyntaxError();
            
            this.hasSyntaxError = !SyntaxCheck.SyntaxOkay;
            this.ErrorMsg = SyntaxCheck.ErrorMessage;

            if(SyntaxCheck.SyntaxOkay && this.ErrorShow) {
                this.ErrorShow = false;
            }
        },

        getComparator: {
            handler(newValue, oldValue) {
                this.ReRender();
            },

            deep: true
        },

        currentSlide(newValue, oldValue) {
            this.RecalculateSlide();
        }
    },

    beforeUnmount() {
        this.$refs.slidesContainer.removeEventListener("touchstart", this.OnTouchstart, { passive: true });
        this.$refs.slidesContainer.removeEventListener("touchend", this.OnTouchend, { passive: true });
        this.$refs.slidesContainer.removeEventListener("touchmove", this.OnTouchmove, { passive: true });

        window.removeEventListener("resize", this.OnResize, { passive: true });
    }

}
</script>

<style lang="scss" scoped>
.slider-wrapper {
    position: relative;
    overflow: hidden;
}

.slides-container {
    width: 100%;
    display: flex;
    overflow-y: hidden;
    overflow-x: hidden;
    scroll-behavior: smooth;
    list-style: none;
    margin: 0;
    padding: 0;
}

.slide {
  width: 100%;
  height: 100%;
  flex: 1 0 100%;
}
</style>