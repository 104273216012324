<template>
    <button class="btn px-3 rounded-0 copy-button col-2" :class="{'btn-dyellow': !this.hasCopied, 'btn-success': this.hasCopied, 'text-white': this.hasCopied}" @click="Copy()">
        <div v-if="!this.hasCopied">
            <i class="fa-light fa-copy"></i>
            <div class="font-size-6">{{ this.$t("msg.copy") }}</div>
        </div>
        <div v-if="this.hasCopied">
            <i class="fa-light fa-check"></i>
            <div class="font-size-6">{{ this.$t("msg.done") }}</div>
        </div>
    </button>
</template>

<script>
import copy from 'copy-text-to-clipboard';

export default {
    name: 'CopyButton',

    props: {
        text: String
    },

    data() {
        return {
            hasCopied: false
        }
    },

    methods: {
        Copy() {
            this.hasCopied = true;
            copy(this.text);

            var t = this;
            window.setTimeout(function() {
                t.hasCopied = false;
            }, 1250);
        },
    }
}
</script>

<style lang="scss" scoped>
</style>