import { createApp } from 'vue'
import App from './App.vue'
import store from './store'

import axios from 'axios'
import VueAxios from 'vue-axios'
import mitt from 'mitt'

import { createI18n } from 'vue-i18n';
import en from '../locales/en.json';
import de from '../locales/de.json';
import es from '../locales/es.json';
import fr from '../locales/fr.json';
import it from '../locales/it.json';
import ja from '../locales/ja.json';
import kr from '../locales/kr.json';
import pt from '../locales/pt.json';
import zh from '../locales/zh.json';

function getPreferredLanguage() {
    // Check if <html lang="..."> is set
    const htmlLang = document.documentElement.lang;
    if (htmlLang) {
        return htmlLang.split('-')[0].toLowerCase(); // Normalize to base language code
    }

    // Check the browser's language settings
    const browserLang = navigator.language || navigator.userLanguage;
    if (browserLang) {
        return browserLang.split('-')[0].toLowerCase(); // Normalize to base language code
    }

    // Default to English if no other language is found
    return 'en';
}

const i18n = createI18n({
    locale: getPreferredLanguage(),
    fallbackLocale: 'de',
    messages: {
        en,
        de,
        es,
        fr,
        it,
        ja,
        kr,
        pt,
        zh
    }
});


const emitter = mitt();
const app = createApp(App);

app.config.globalProperties.emitter = emitter;
app.config.globalProperties.$type = "derivative";
app.config.globalProperties.$mathml = (function () {
    const div = document.createElement("div");
    div.innerHTML = '<math><mfrac><mn>1</mn><mn>2</mn></mfrac></math>' +
                    '<math><mn>1</mn></math>';
    document.body.appendChild(div);
    
    const isSupported = div.firstElementChild.firstElementChild.getBoundingClientRect().height > div.lastElementChild.firstElementChild.getBoundingClientRect().height + 1;
    
    div.remove();
    
    return isSupported;
})();

app.config.globalProperties.$api = "https://derivative-calculator.org/api/diff.php";

app.use(store).use(i18n).use(VueAxios, axios).mount('#app')
