import { createStore } from 'vuex'

export default createStore({
    state() {
        return {
            loading: false,

            expression: "",
            solution: "",
            variable: "x",

            comparator: {}
        }
    },
    mutations: {
        setLoading(state, payload) {
            state.loading = payload;
        },
        setExpression(state, payload) {
            state.expression = payload;
        },
        setSolution(state, payload) {
            state.solution = payload;
        },
        setVariable(state, payload) {
            state.variable = payload;
        },
        setComparator(state, payload) {
            state.comparator = payload;
        },
        setResult(state, payload) {
            state.variable = payload.variable;
        },
    },
    getters: {
        isLoading: state => {
            return state.loading;
        },
        getExpression: state => {
            return state.expression;
        },
        getSolution: state => {
            return state.solution;
        },
        getVariable: state => {
            return state.variable;
        },
        getComparator: state => {
            return state.comparator;
        }
    }
});
