<template>
    <div class="input-group mb-3">
        <input class="form-control" :value="this.text" readonly />
        <button class="btn" :class="{'btn-dyellow': !this.hasCopied, 'btn-success': this.hasCopied, 'text-white': this.hasCopied}" type="button" id="button-addon2" @click="this.Copy">
            <div v-if="!this.hasCopied">
                <i class="fa-light fa-copy"></i>
                <div class="font-size-6">{{ this.$t("msg.copy") }}</div>
            </div>
            <div v-if="this.hasCopied">
                <i class="fa-light fa-check"></i>
                <div class="font-size-6">{{ this.$t("msg.done") }}</div>
            </div>
        </button>
    </div>
</template>

<script>
import copy from 'copy-text-to-clipboard';

export default {
    name: "CopyBox",

    props: {
        text: String,
    },

    data() {
        return {
            hasCopied: false
        }
    },

    methods: {
        Copy() {
            this.hasCopied = true;
            copy(this.text);

            var t = this;
            window.setTimeout(function() {
                t.hasCopied = false;
            }, 1250);
        },
    }
};
</script>

<style lang="scss" scoped>
button {
    width: 4.5rem;
}
</style>
