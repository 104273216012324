<template>
    <div class="text-center" :class="{'d-none': !this.show}" v-html="this.Loader"></div>
</template>

<script>
export default {
    name: 'MiniLoader',

    props: {
        show: Boolean
    },

    data() {
        return {
            inner: this.Loader
        }
    },

    computed: {
        Loader() {
            return window.atob('PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSI0IiBjeT0iMTIiIHI9IjMiPjxhbmltYXRlIGlkPSJzcGluX2EiIGJlZ2luPSIwO3NwaW5fYi5lbmQtMC4yNXMiIGF0dHJpYnV0ZU5hbWU9InIiIGR1cj0iMC43NXMiIHZhbHVlcz0iMzsuMjszIi8+PC9jaXJjbGU+PGNpcmNsZSBjeD0iMTIiIGN5PSIxMiIgcj0iMyI+PGFuaW1hdGUgYmVnaW49InNwaW5fYS5lbmQtMC42cyIgYXR0cmlidXRlTmFtZT0iciIgZHVyPSIwLjc1cyIgdmFsdWVzPSIzOy4yOzMiLz48L2NpcmNsZT48Y2lyY2xlIGN4PSIyMCIgY3k9IjEyIiByPSIzIj48YW5pbWF0ZSBpZD0ic3Bpbl9iIiBiZWdpbj0ic3Bpbl9hLmVuZC0wLjQ1cyIgYXR0cmlidXRlTmFtZT0iciIgZHVyPSIwLjc1cyIgdmFsdWVzPSIzOy4yOzMiLz48L2NpcmNsZT48L3N2Zz4=');
        }
    },

    watch: {
        show(newValue, oldValue) {
            if(newValue) {
                this.inner = this.Loader;
            } else {
                this.inner = "";
            }
        }
    }
}
</script>

<style lang="scss" scoped>
circle {
    fill: #3f454c;
}
</style>