<template>
    <div class="modal fade" :class="{'show': modalFade, 'd-block': show, 'up': !noUp}" id="modal" tabindex="-1" aria-labelledby="modalHeading" aria-hidden="true" v-on:keyup.esc="hideModal">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" :class="{'modal-lg': wide, 'modal-sm': small, 'modal-xl': extrawide}">
            <div class="modal-content bg-white" role="document">
                <div class="modal-header" v-if="heading">
                    <h5 class="modal-title" id="modalHeading">{{ heading }}</h5>
                    <button type="button" class="btn-close" @click="hideModal" :aria-label="this.$t('modals.close')"></button>
                </div>
                <div class="modal-body">
                    <slot name="content"></slot>
                </div>
                <div class="modal-footer" v-if="this.$slots.footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Modal',

    props: {
        heading: String,
        id: String,
        small: {
            type: Boolean,
            default: false
        },
        wide: {
            type: Boolean,
            default: false
        },
        extrawide: {
            type: Boolean,
            default: false
        },
        noDimmedBackground: {
            type: Boolean,
            default: false
        },
        noScroll: {
            type: Boolean,
            default: true
        },
        noUp: Boolean,
    },

    mounted() {
        var t = this;

        this.emitter.on("Show" + this.id, () => {
            t.show = true;
        });
        this.emitter.on("Hide" + this.id, () => {
            if(t.show == true) {
                t.show = false;
            }
        });
        this.emitter.on("onCancel", () => {
            if(t.show == true) {
                t.show = false;
            }
        });
    },

    data() {
        return {
            modalFade: false,
            show: false,
        }
    },

    methods: {
        hideModal() {
            this.show = false;
        },
    },

    watch: {
        show(show) {
            if(!this.noDimmedBackground) {
                if(this.noScroll) {
                    document.body.classList.add("modal-open");
                }
                if(!document.getElementById("modalback")) {
                    var modalback = document.createElement("div");
                    modalback.id = "modalback";

                    document.body.appendChild(modalback);
                }

                var modalBackground = document.getElementById("modalback");
                if(show) {
                    modalBackground.classList.add("fade");
                    window.setTimeout(() => {
                        modalBackground.classList.add("show");
                        this.modalFade = true;
                    }, 150);

                } else {
                    modalBackground.classList.remove("show");
                    this.modalFade = false;

                    window.setTimeout(() => {
                        modalBackground.classList.remove("fade");
                        modalBackground.parentNode.removeChild(modalBackground);
                    }, 150);

                    document.body.classList.remove("modal-open");
                }

            } else {
                this.modalFade = show;
            }
        }
    }

}
</script>

<style lang="scss">
.modal {
    z-index: 1072;

    &.up {
        transform: translateY(-17vh);
    }
}

#modalback {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1071;
    width: 100vw;
    height: 100vh;
    background-color: #000;

    &.show {
        opacity: 0.5;
    }

    &.fade {
        transition: opacity .15s linear;
    }
}
</style>