<template>
    <div class="dotcontainer position-relative w-100" :class="{'d-none': !this.isLoading}">
        <div class="dots">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
    </div>
    <div class="text-center font-size-12 fw-bold" :class="{'d-none': !this.isLoading}">{{ this.loadingText }}</div>
</template>

<script>

export default {
    name: 'Waves',

    data() {
        return {
            loadingText: null
        };
    },

    mounted() {
        this.NextLoadingText();
    },

    methods: {
        NextLoadingText: function() {
            const texts = JSON.parse(this.$t("loading"));
            this.loadingText = texts[Math.floor(Math.random() * texts.length)];
        }
    },

    computed: {
        isLoading() {
            return this.$store.getters.isLoading;
        }
    },

    watch: {
        isLoading() {
            this.NextLoadingText();
        }
    }
}
</script>

<style lang="scss" scoped>
$dot-color: #63A3B2;
$dot-size: 35px;
$circle-size: 70px;
$animation-time: 2.75s;

$color-yellow: #FBD178;
$color-red: #e9603a;
$color-blue: #63A3B2;
$color-green: #59a35b;

$colors: ($color-red, $color-blue, $color-green, $color-yellow);

.dotcontainer {
    height: 200px;
}

.dots {
	width: 0;
	height: 0;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
}

.dot {
	width: 0;
	height: 0;
	position: absolute;
	left: 0;
	top: 0;
 

	&:before {
		content: '';
		width: $dot-size;
		height: $dot-size;
		border-radius: 50px;
		background: $color-yellow;
		position: absolute;
		left: 50%;
		transform: translateY(0) rotate(0deg);
		margin-left: -($dot-size/2);
		margin-top: -($dot-size/2);
	}


	@keyframes dot-move {
		0% {
			transform: translateY(0);
		}

		18%, 22% {
			transform: translateY(-($circle-size));
		}

		40%, 100% {
			transform: translateY(0);
		}
	}

	@keyframes dot-colors {
		@for $i from 0 to 4 {
			#{$i*25%} {
				background-color: nth($colors, ($i+3)%4+1);
			}
		}

		100% {
			background-color: nth($colors, 4);
		}
	}

	&:nth-child(5):before {
		z-index: 100;
		width: $dot-size * 1.3;
		height: $dot-size * 1.3;
		margin-left: -($dot-size * .65);
		margin-top: -($dot-size * .65);
		animation: dot-colors $animation-time ease infinite;
	}

	@for $i from 0 to 4 {
		@keyframes dot-rotate-#{$i + 1} {
			0% {
				transform: rotate(#{($i+1)*270deg - 375deg});
			}

			100% {
				transform: rotate(#{($i+1)*270deg + 0deg});
			}
		}

		&:nth-child(#{$i + 1}) {
			animation: dot-rotate-#{$i + 1} $animation-time #{$i * $animation-time / 4} linear infinite;

			&:before {
				background-color: nth($colors, $i+1);
				animation: dot-move $animation-time #{$i * $animation-time/4} ease infinite;
			}
		}
	}
} 
</style>
