<template>

    <div class="d-flex justify-content-end actions mt-4 flex-column align-items-end flex-md-row">
        <button class="btn btn-sm text-body btn-warning d-flex align-items-center" v-if="!this.isExportHidden" @click="Export()">
            <span>{{ this.$t("buttons.export") }}</span>
            <i class="ms-2 fal fa-file-export font-size-10"></i>
        </button>
        <button class="btn btn-sm text-white btn-primary d-flex align-items-center my-2 my-md-0" :disabled="this.isFindingRoots" v-if="!this.isRootsHidden" @click="Solve()">
            <span>{{ this.$t("buttons.roots") }}</span>
            <i class="ms-2 fal fa-magnifying-glass-chart font-size-10"></i>
        </button>
        <button class="btn btn-sm text-white btn-info d-flex align-items-center" :disabled="this.isSimplifying" v-if="!this.isSimplifyHidden" @click="Simplify()">
            <span>{{ this.$t("buttons.simp") }}</span>
            <i class="ms-2 fal fa-arrow-down-square-triangle font-size-10"></i>
        </button>
        <button class="btn btn-sm text-white btn-success d-flex align-items-center my-2 my-md-0" v-if="!this.isCompareHidden" @click="Compare()">
            <span>{{ this.$t("buttons.compare") }}</span>
            <i class="ms-2 fal fa-file-check font-size-10"></i>
        </button>
    </div>

    <Modal :heading="this.$t('modals.export')" :id="this.ExportModalName" :noUp="true">
        <template v-slot:content>
            <p class="mb-3">{{ this.$t("msg.export") }}</p>

            <div class="p-3">
                <div v-for="(key, index) in ['latex', 'mathematica', 'matlab', 'cpp', 'python']" :key="key">
                    <div v-if="this.ExportFormats && key in this.ExportFormats && !!this.ExportFormats[key]" class="mb-2 mt-2 export-container">
                        <div class="fw-bold ms-2">{{ this.$t("export." + key) }}</div>
                        <div class="rounded-4 border border-5 font-monospace scroll-x row">
                            <div class="col-10 d-flex copy-item" :class="{'align-items-start': this.ExportFormats[key].length > 42, 'align-items-center': this.ExportFormats[key].length <= 42 }">
                                {{ this.ExportFormats[key] }}
                            </div>
                            <CopyButton :text="this.ExportFormats[key]"></CopyButton>
                        </div>
                    </div>
                </div>
            </div>

        </template>
        <template v-slot:footer>
            <button type="button" class="btn btn-dark btn-sm px-4" @click="this.closeModals()">
                {{ this.$t('modals.done-button') }}
            </button>
        </template>
    </Modal>

    <Modal :heading="this.$t('modals.compare')" :id="this.ExpressionCompareModalName" :noUp="true" :wide="true">
        <template v-slot:content>
            <EqualityChecker :parent="this.guid"></EqualityChecker>
        </template>
        <template v-slot:footers>
            <button class="btn btn-sm text-white btn-dark d-flex align-items-center my-2 my-md-0">
                <span>{{ this.$t('modals.compare-button') }}</span>
                <i class="ms-2 fal fa-code-compare font-size-10"></i>
            </button>
        </template>
    </Modal>

    <Collapse :show="this.showRoots">
        <div class="p-3 rounded rounded-4 mt-4 border-2 border border-5 actionresult" ref="roots">
            <div class="fw-bold font-size-10">{{ this.$t("msg.root_title") }}</div>
            <MiniLoader :show="!this.Roots"></MiniLoader>

            <div class="alert alert-primary small p-2 text-center mt-4 mb-0" role="alert" v-if="!this.isObj(this.Roots) && Number.isInteger(this.Roots)">
                <div v-if="this.Roots == -1">
                    {{ this.$t("errors.server-error") }}
                </div>
                <div v-if="this.Roots == -2">
                    {{ this.$t("errors.timeout") }}
                </div>
            </div>

            <div v-if="this.Roots && this.isObj(this.Roots) && 'latex' in this.Roots && this.Roots['latex'] !== null">
                <div class="fw-bold fst-italic my-3 text-center" v-if="this.Roots['latex'].length == 0 || this.Roots['latex'] == '\\left[ \\right]'">No roots found.</div>
                <div v-else>
                    <div class="mt-4" v-if="this.$mathml" v-html="this.Roots['mathml']"></div>
                    <div class="mt-4" v-else>$$ {{ this.Roots['latex'] }} $$</div>

                    <div class="d-none d-md-flex justify-content-end actions mb-2 flex-column align-items-end flex-md-row">
                        <button class="btn btn-sm text-body btn-warning d-flex align-items-center" @click="this.Export(this.Roots)">
                            <span>{{ this.$t("buttons.export") }}</span>
                            <i class="ms-2 fal fa-file-export font-size-10"></i>
                        </button>
                    </div>
                </div>

                <div class="alert alert-warning small p-2 text-center mb-0" role="alert">
                    {{ this.$t("errors.not-all-solutions") }}
                </div>
            </div>
        </div>
    </Collapse>

    <Collapse :show="this.showSimplifications">
        <div class="p-3 rounded rounded-4 mt-4 border-2 border border-5 actionresult" ref="simplifications">
            <div class="fw-bold font-size-10">{{ this.$t("msg.simp_title") }}</div>
            <MiniLoader :show="this.Simplifications === null"></MiniLoader>

            <div class="alert alert-primary small p-2 text-center mt-4 mb-0" role="alert" v-if="!this.isObj(this.Simplifications) && Number.isInteger(this.Simplifications)">
                <div v-if="this.Simplifications == -1">
                    {{ this.$t("errors.server-error") }}
                </div>
                <div v-if="this.Simplifications == -2">
                    {{ this.$t("errors.timeout") }}
                </div>
            </div>

            <div v-if="this.Simplifications && this.isObj(this.Simplifications)" v-for="(key, index) in Object.keys(this.Filter(this.Simplifications))" :key="key">
                <div v-if="this.Simplifications[key] && typeof this.Simplifications[key] === 'object' && !Array.isArray(this.Simplifications[key]) && this.Simplifications[key] !== null" class="item mt-3" :class="{'border-bottom': index != this.SimplificationLength - 1 }">
                    <div class="fw-bold">{{ this.$t("simplification." + key) }}</div>
                    <div v-if="this.$mathml" v-html="this.Simplifications[key]['mathml']" class="my-4"></div>
                    <div v-else>$$ {{ this.Simplifications[key]['latex'] }} $$</div>
                    <div class="d-none d-md-flex justify-content-end actions mb-2 flex-column align-items-end flex-md-row">
                        <button class="btn btn-sm text-body btn-warning d-flex align-items-center" @click="this.Export(this.Simplifications[key])"><span>{{ this.$t("buttons.export") }}</span><i class="ms-2 fal fa-file-export font-size-10"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </Collapse>

    <Collapse :show="this.showEqualChecks">
        <div class="p-3 rounded rounded-4 mt-4 border-2 border border-5 actionresult" ref="equality">
            <div class="fw-bold font-size-10">{{ this.$t("msg.equals_title") }}</div>
            <MiniLoader :show="this.Equals === null"></MiniLoader>

            <div class="alert alert-primary small p-2 text-center mt-4 mb-0" role="alert" v-if="Number.isInteger(this.Equals)">
                <div v-if="this.Equals == -1">
                    {{ this.$t("errors.server-error") }}
                </div>
                <div v-if="this.Equals == -2">
                    {{ this.$t("errors.timeout") }}
                </div>
                <div v-if="this.Equals == -3">
                    {{ this.$t("errors.general") }}
                </div>
            </div>

            <div v-if="this.Equals && typeof this.Equals == 'object' && typeof this.Equals.res == 'boolean'" class="item mt-3">
                <div v-if="this.$mathml" v-html="this.Equals['mathml']" class="my-4"></div>
                <div v-else>$$ {{ this.Equals['left_raw'] }} \, {{ this.Equals.sign }} \, {{ this.Equals['right_raw'] }} $$</div>

                <div class="row rounded border border-success border-2 w-100 mx-auto mt-2" role="alert" v-if="this.Equals.res === true">
                    <div class="col-3 col-md-2 col-lg-1 bg-success text-white cen"><i class="fa-light fa-circle-check font-size-15"></i></div>
                    <div class="col fw-bold p-3">{{ this.$t("msg.compare-equal") }}</div>
                </div>

                <div class="row rounded border border-primary border-2 w-100 mx-auto mt-2" role="alert" v-if="this.Equals.res === false">
                    <div class="col-3 col-md-2 col-lg-1 bg-primary text-white cen"><i class="fa-light fa-circle-check font-size-15"></i></div>
                    <div class="col fw-bold p-3">{{ this.$t("msg.compare-not-equal") }}</div>
                </div>

            </div>
        </div>
    </Collapse>
</template>

<script>
import Temml from 'temml';
import Modal from './Modal.vue';
import MiniLoader from './MiniLoader.vue';
import Collapse from './Collapse.vue';
import CopyButton from './CopyButton.vue';
import EqualityChecker from './EqualityChecker.vue';
import axios from 'axios';
import qs from 'qs';

export default {
    name: 'ActionButtons',

    components: {
        Modal,
        MiniLoader,
        EqualityChecker,
        CopyButton,
        Collapse
    },

    data() {
        return {
            showRoots: false,
            showSimplifications: false,
            showEqualChecks: false,

            isSimplifying: false,
            isFindingRoots: false,

            Simplifications: null,
            Roots: null,
            Equals: null,

            ExportFormats: null,

            guid: -1,

            hideExportButton: null,
            hideSimplifyButton: null,
            hideRootButton: null,
            hideCompareButton: null
        }
    },

    props: {
        expression: String,
        variable: String,

        id: String,

        hideExport: {
            type: Boolean,
            default: false
        },
        hideRoots: {
            type: Boolean,
            default: false
        },
        hideSimplify: {
            type: Boolean,
            default: false
        },
        hideComparison: {
            type: Boolean,
            default: false
        },

        formats: Array
    },

    mounted() {
        this.guid = Math.round(Math.random() * 10e10).toString(16);

        if(typeof this.formats != "object" || (typeof this.formats == "object" && Object.keys(this.formats).length == 0)) {
            this.hideExportButton = true;
            this.hideSimplifyButton = true;
            this.hideRootButton = true;
            this.hideCompareButton = true;
        }

        const t = this;
        this.emitter.on("Compare", (payload) => {
            if(payload && typeof payload == "object" && "parent" in payload) {
                if(t.guid == payload["parent"]) {
                    t.showEqualChecks = true;
                    t.Equals = null;
                    t.$refs.equality.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });

                    // Make post request 
                    axios.post(this.$api, qs.stringify({
                        action      : "equality",
                        expression1 : payload["expression"],
                        expression2 : payload["comparator"]
                    }), {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        timeout: 14500
                    }).then(function(response) {
                        if(!response || !response.data || !("res" in response.data)) {
                            t.Equals = -1;
                            return;
                            
                        } else if(typeof response.data.res !== "boolean") {
                            t.Equals = -3;
                            return;
                        }

                        t.Equals = response.data;
                        t.Equals["sign"] = "=";
                        if(response.data.res === false) {
                            t.Equals["sign"] = "\\neq";
                        }

                        if(!t.$mathml) {
                            window.setTimeout(function() {
                                MathJax.typesetPromise();
                            }, 100);
                        }
                    }).catch(error => {
                        if(!!~error.message.indexOf("timeout")) {
                            t.Equals = -2;
                        }
                    }).finally(i => {
                        window.setTimeout(function() {
                            t.$refs.equality.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
                        }, 100);
                    });


                }
            }
        });
    },

    methods: {
        Export: function(fmts) {
            if(fmts === undefined) {
                this.ExportFormats = this.formats;
            } else {
                this.ExportFormats = fmts;
            }

            this.emitter.emit("Show" + this.ExportModalName);
        },
        Compare: function() {
            this.$store.commit('setComparator', this.formats);
            this.emitter.emit("Show" + this.ExpressionCompareModalName);
        },
        Solve: function() {
            this.showRoots = true;
            this.isFindingRoots = true;
            var t = this;
            
            axios.post(this.$api, qs.stringify({
                action     : "solve",
                expression : this.expression,
                var        : "x",
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                timeout: 14500
            }).then(function(response) {
                if(!response || !response.data || !response.data["res"]) {
                    t.Roots = -1;
                    return;
                }

                t.Roots = response.data["res"];

                if(!t.$mathml) {
                    window.setTimeout(function() {
                        MathJax.typesetPromise();
                    }, 100);
                }
            }).catch(error => {
                if(!!~error.message.indexOf("timeout")) {
                    t.Roots = -2;
                }
                console.error("ERROR", error);
            }).finally(i => {
                window.setTimeout(function() {
                    t.$refs.roots.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
                }, 100);
            });

        },
        Simplify: function() {
            this.showSimplifications = true;
            this.isSimplifying = true;
            var t = this;

            axios.post(this.$api, qs.stringify({
                action     : "simplify",
                expression : this.expression
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                timeout: 14500
            }).then(function(response) {
                if(!response || !response.data || typeof response.data != "object") {
                    t.Simplifications = -1;
                    return;
                }

                t.Simplifications = response.data;

                if(!t.$mathml) {
                    window.setTimeout(function() {
                        MathJax.typesetPromise();
                    }, 100);
                }
            }).catch(error => {
                if(!!~error.message.indexOf("timeout")) {
                    t.Simplifications = -2;
                }
            }).finally(i => {
                window.setTimeout(function() {
                    t.$refs.simplifications.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
                }, 100);
            });

        },
        Filter(arr) {
            const keys = Object.keys(arr);
            var out = {};
            for (var i=0; i<keys.length; i++) {
                const key = keys[i];

                if( arr[key] &&
                    typeof arr[key] === 'object' &&
                    !Array.isArray(arr[key]) &&
                    arr[key] !== null) {
                        out[key] = arr[key];
                }
            }

            return out;
        },
        closeModals() {
            this.emitter.emit("onCancel");
        },
        isObj(x) {
            return x !== undefined && typeof x === 'object' && !Array.isArray(x) && x !== null;
        },
        makeMathML(latex, match = true) {
            latex = latex.replaceAll("^{}", "");
            if(match) {
                const t = this;
                latex = latex.replace(/\$\$\s*(.*?)\s*\$\$/gims, (match, p1) => {
                    return Temml.renderToString(p1, { displayMode: true });
                });
                latex = latex.replace(/\\\(\s*(.*?)\s*\\\)/gims, (match, p1) => {
                    return Temml.renderToString(p1, { displayMode: false });
                });
                latex = latex.replace(/\\\[\s*(.*?)\s*\\\]/gims, (match, p1) => {
                    return Temml.renderToString(p1, { displayMode: false });
                });

            } else {
                latex = Temml.renderToString(latex, { displayMode: true });
            }

            return latex;
        }

    },

    computed: {
        isLoading() {
            return this.$store.getters.isLoading;
        },
        ExportModalName() {
            return 'VariableExportModal' + this.id;
        },
        ExpressionCompareModalName() {
            return 'ExpressionCompareModal' + this.id;
        },
        SimplificationLength() {
            var runner = 0;
            const keys = Object.keys(this.Simplifications);
            for (var i=0; i<keys.length; i++) {
                const key = keys[i];

                if( this.Simplifications[key] &&
                    typeof this.Simplifications[key] === 'object' &&
                    !Array.isArray(this.Simplifications[key]) &&
                    this.Simplifications[key] !== null) {
                        runner++;
                }
            }
            return runner;
        },

        isExportHidden() {
            if(this.hideExportButton === null || this.hideExportButton === undefined) {
                return this.hideExport;
            }

            return this.hideExportButton;
        },
        isSimplifyHidden() {
            if(this.hideSimplifyButton === null || this.hideSimplifyButton === undefined) {
                return this.hideSimplify;
            }

            return this.hideSimplifyButton;
        },
        isRootsHidden() {
            if(this.hideRootButton === null || this.hideRootButton === undefined) {
                return this.hideRoots;
            }

            return this.hideRootButton;
        },
        isCompareHidden() {
            if(this.hideCompareButton === null || this.hideCompareButton === undefined) {
                return this.hideComparison;
            }

            return this.hideCompareButton;
        },

        MathMLRoots() {
            if(this.Roots && "latex" in this.Roots) {
                return this.makeMathML(this.Roots['latex'], false);
            }
            return "";
        }
    },

}
</script>

<style lang="scss" scoped>
.actionresult, .scroll-x {
    overflow-x: auto;
    scrollbar-width: thin;
}

.export-container {
    .copy-item {
        max-height: 3.75rem;
        overflow-y: auto;
        scrollbar-width: thin;
    }
}
</style>
